import React, { useContext } from "react";
import Title from "../../components/title/title";
import { useSelector } from "react-redux";
import { GlobalState } from "../../data/Context";
import Users from "../users";

const Dashboard = () => {
	let { examType, subject, auth, candidates } = useSelector(s => s),
		{ numberWithCommas } = useContext(GlobalState);

	return (
		<>
			<div className="max-w-[90%] mx-auto">
				<Title title="DASHBOARD" />
				<div className="bg-white my-8 gap-8 md:h-48 rounded-3xl shadow-lg flex-col md:flex-row flex justify-center items-center p-4 h-full w-full">
					{/* First shape */}
					<div className="flex items-center gap-x-4 w-full md:w-1/5">
						<img
							src={[require("../../assets/candidate.png")]}
							className="w-16 h-16"
							alt=""
						/>
						<div>
							<h1 className="text-sm font-medium text-[#707070]">
								Total Candidates
							</h1>
							<p className="text-lg font-bold">
								{numberWithCommas(candidates?.data?.totalDocs || 0)}
							</p>
						</div>
					</div>
					<div className="border-2 border-gray-200 w-px h-48"></div>

					{/* Second shape */}
					<div className="flex items-center gap-x-4 w-full md:w-1/5">
						<img
							src={[require("../../assets/subjects.png")]}
							className="w-16 h-16 "
							alt=""
						/>
						<div>
							<h1 className="text-sm font-medium text-[#707070]">
								Total Exams
							</h1>
							<p className="text-lg font-semibold">
								{numberWithCommas(examType?.data?.totalDocs || 0)}
							</p>
						</div>
					</div>
					<div className="border-2 border-gray-200 w-px h-48"></div>

					{/* Third shape */}
					<div className="flex items-center gap-x-4 w-full md:w-1/5">
						<img
							src={[require("../../assets/questions.png")]}
							className="w-16 h-16 "
							alt=""
						/>
						<div>
							<h1 className="text-sm font-medium text-[#707070]">
								Total Subjects
							</h1>
							<p className="text-lg font-semibold">
								{numberWithCommas(subject?.data?.totalDocs || 0)}
							</p>
						</div>
					</div>
					<div className="border-2 border-gray-200 w-px h-48"></div>

					{/* Fourth shape */}
					<div className="flex items-center gap-x-4 w-full md:w-1/5">
						<img
							src={[require("../../assets/questions.png")]}
							className="w-16 h-16 "
							alt=""
						/>
						<div>
							<h1 className="text-sm font-medium text-[#707070]">
								Total Questions
							</h1>
							<p className="text-lg font-semibold">
								{numberWithCommas(auth?.questionsLength || 0)}
							</p>
						</div>
					</div>
				</div>
				<Users />
			</div>
		</>
	);
};

export default Dashboard;
