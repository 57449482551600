import React, { useContext, useEffect, useState } from "react";
import Title from "../../components/title/title";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../data/Context";
import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import moment from "moment";
import ModalContainer from "../../components/modal-container/modal-container";
import AddButton from "../../components/button/addbutton";
import { Buttons } from "../../components/button/button";
import { MainPaginate } from "../../components/pagination/Pagination";
import { createMarkup } from "../../components/ManageQuestion/ManageQuestion";

const AdminDetails = () => {
	let items = useLocation()?.state;
	let [state, setState] = useState(items),
		{ candidates, auth } = useSelector(s => s),
		navigate = useNavigate(),
		{ numberWithCommas } = useContext(GlobalState),
		[modal, setModal] = useState(""),
		[loading, setLoading] = useState(null),
		dispatch = useDispatch(),
		[datum, setDatum] = useState(null),
		[datumAdmin, setDatumAdmin] = useState(null),
		textChange = e => {
			let { name, value } = e?.target;
			setDatum(prev => {
				let prevData = { ...prev };
				prevData[name] = value;
				return prevData;
			});
		},
		handleSubmit = async e => {
			try {
				if (e) e?.preventDefault();
				if (datum) if (Object.entries(datum)?.length !== 2) return;
				setLoading(true);
				let res5 = await axios.get(
					`/api/v1/exam/manage-exam-createdby?createdBy=${state?._id}${
						datum?.startDate ? `&startDate=${datum?.startDate}` : ""
					}${datum?.endDate ? `&endDate=${datum?.endDate}` : ""}${
						!datum?.endDate && !datum?.startDate
							? `&actualDate=${moment().format("YYYY-MM-DD")}`
							: ""
					}`
				);
				setDatumAdmin(res5?.data?.data);
				console.log({ adminStatistics: res5?.data });
				setLoading(false);
				setDatum({});
				setModal("");
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);

				let error = err.response?.data?.error;
				if (error) {
					setLoading(false);
					return dispatch(
						returnErrors({ error, status: err?.response?.status })
					);
				} else {
					setLoading(false);
					return toast.error(err?.response?.data?.message);
				}
			}
		};

	useEffect(() => {
		if (auth?.user?.privilege !== "superadmin") navigate(-1);
	}, [auth?.user?.privilege, navigate]);

	useEffect(() => {
		candidates?.admin?.docs?.map(it => it?._id === items?._id && setState(it));
	}, [candidates?.admin, items]);

	useEffect(() => {
		handleSubmit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	const Headers = [
		"S/N",
		"Exam Type",
		"Subject",
		"Year",
		"Question",
		"Right Answer",
	];

	let [range] = useState(10);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	const currentItems = datumAdmin?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datumAdmin?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datumAdmin?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div className={"max-w-[90%] mx-auto"}>
			<Title title={"Admin Details"} />
			<div className="bg-white h-auto rounded-3xl shadow-lg mt-10 p-6 ">
				<div className="flex md:flex-row justify-between items-center flex-col">
					<div className="flex items-center gap-3">
						<AddButton
							onClick={() => navigate(-1)}
							title={"Back"}
							type="back"
						/>
						<h1 className="text-lg font-bold capitalize">
							{state?.firstName} {state?.lastName}
						</h1>
					</div>
					<AddButton
						onClick={() => {
							setModal(true);
						}}
						title={"Admin Stat"}
						noIcon
					/>
				</div>
				{datumAdmin && (
					<>
						<div className="mt-10">
							<h2 className="text-xl text-black font-normal work">
								Question Statistics:{" "}
								{numberWithCommas(datumAdmin?.totalDocs || 0)}
							</h2>
							<table className="w-full table-auto">
								<thead className="w-full h-10 rounded-t-3xl bg-[#E7E7F6]">
									<tr>
										{Headers?.map((item, i) => (
											<th
												key={i}
												className="text-sm first-letter:uppercase text-[#838080] work px-3">
												{item}
											</th>
										))}
									</tr>
								</thead>
								<tbody className="divide-y-4">
									{currentItems?.map((item, i) => (
										<tr
											style={{
												boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
												marginBottom: "10px !important",
											}}
											key={i}
											className="work bg-white border-b hover:bg-gray-100 cursor-pointer">
											<td className="text-black font-normal work text-sm text-center py-6">
												{i + 1}
											</td>
											<td className="text-black font-normal work text-sm text-center uppercase">
												{item?.examType?.title}
											</td>
											<td className="text-black font-normal work text-sm text-center uppercase py-6">
												{item?.subject?.title}
											</td>
											<td className="text-black font-normal work text-sm text-center uppcase py-6">
												{item?.year?.title}
											</td>
											<td className="text-black font-normal work text-sm text-center py-6">
												{/* {item?.question} */}
												<span
													dangerouslySetInnerHTML={createMarkup(item?.question)}
												/>
											</td>
											<td className="text-black font-normal work text-sm text-center py-6">
												{item?.options?.filter(it => it?.isCorrect)?.[0]
													?.optionType === "file" ? (
													<></>
												) : (
													<>
														<span
															dangerouslySetInnerHTML={createMarkup(
																item?.options?.filter(it => it?.isCorrect)?.[0]
																	?.option
															)}
														/>
													</>
												)}
											</td>
										</tr>
									))}
								</tbody>
							</table>
							<MainPaginate
								pageCount={pageCount}
								handlePageClick={handlePageClick}
							/>
						</div>
					</>
				)}
			</div>
			<ModalContainer
				show={modal}
				title="Date Range"
				width={"max-w-md"}
				close={() => setModal(false)}>
				<div className="flex justify-between mt-4">
					<h2 className="segoe text-xl font-bold text-text-black capitalize">
						Stat Range
					</h2>
				</div>
				<form className="mt-5">
					<div className="space-y-4">
						<div className="">
							<label htmlFor="input" className=" font-semibold text-[20px]">
								Start Date
							</label>
							<input
								type="date"
								id="input"
								className="border rounded w-full py-2 px-3 text-gray-700 my-4 shadow"
								value={datum?.startDate}
								onChange={textChange}
								max={moment().format("YYYY-MM-DD")}
								name={"startDate"}
							/>
						</div>
						{datum?.startDate && (
							<div className="">
								<label htmlFor="input" className=" font-semibold text-[20px]">
									End Date
								</label>
								<input
									type="date"
									id="input"
									className="border rounded w-full py-2 px-3 text-gray-700 my-4 shadow"
									value={datum?.endDate}
									onChange={textChange}
									name={"endDate"}
									max={moment().format("YYYY-MM-DD")}
									min={moment(datum?.startDate).format("YYYY-MM-DD")}
								/>
							</div>
						)}
					</div>
					<div className="mt-6">
						<Buttons
							width={
								"mb-4 px-4 py-2 bg-[#FD5709] rounded-full text-white w-20 h-12 flex justify-center items-center text-lg ms-auto"
							}
							title={"Send"}
							loading={loading}
							onClick={handleSubmit}
							type="submit"
						/>
					</div>
				</form>
			</ModalContainer>
		</div>
	);
};

export default AdminDetails;
