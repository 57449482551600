import React from "react";
import { HiPlus, HiEye } from "react-icons/hi";
import { MdArrowBackIosNew } from "react-icons/md";
// import AddModal from '../modal/addModal';

const AddButton = ({ onClick, type = "", title, noIcon }) => {
	return (
		<div>
			<button
				className="flex items-center gap-x-2 bg-[#2C56AF] text-white py-2 mx-2 px-3 rounded hover:bg-blue-700 font-semibold"
				onClick={onClick}>
				{!noIcon ? (
					<>
						{type === "view" ? (
							<HiEye className="icon" size={24} />
						) : type === "back" ? (
							<MdArrowBackIosNew className="icon" size={24} />
						) : (
							<HiPlus className="icon" size={24} />
						)}
					</>
				) : null}
				<span>{title || "Add"}</span>
			</button>
		</div>
	);
};

export default AddButton;
