import React, { useRef } from "react";
import { HiTrash } from "react-icons/hi";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import MathType from "@wiris/mathtype-ckeditor5/src/plugin";
// import "@wiris/mathtype-ckeditor5/build/styles/mathType.css";

const CreateQuestion = ({
	question,
	handleInputChangeForMutipleItem,
	index,
	handleDeleteRowForMutipleItem,
	questionsLength,
	handleChangeImage,
	handleChangeImageRemoved,
}) => {
	let fileInputRef = useRef();

	console.log({ ck: window.CKEDITOR }); // Ensure CKEditor5 is loaded correctly

	return (
		<div className="mb-4 p-4 border rounded-lg">
			<label className="font-bold">Question {index + 1}:</label>
			{/* <h1 className='text-[]'>{question.question}</h1> */}
			<input
				type="text"
				className="w-full p-2 mt-2 border border-gray-300 rounded shadow hidden"
				// onChange={handleInputField}
				value={question?.question}
				onChange={event =>
					handleInputChangeForMutipleItem(event, index, "question")
				}
			/>
			<CKEditor
				editor={ClassicEditor}
				data={question?.question}
				onReady={editor => {
					// You can store the "editor" and use when it is needed.
					console.log("Editor is ready to use!", editor);
				}}
				// config={{
				// 	// plugins: [MathType],
				// 	toolbar: [
				// 		"heading",
				// 		"|",
				// 		"bold",
				// 		"italic",
				// 		"link",
				// 		"bulletedList",
				// 		"numberedList",
				// 		"blockQuote",
				// 		"insertTable",
				// 		"mediaEmbed",
				// 		"|",
				// 		"undo",
				// 		"redo",
				// 		"|",
				// 		"fontSize",
				// 		"fontFamily",
				// 		"fontColor",
				// 		"fontBackgroundColor",
				// 		"highlight",
				// 		"|",
				// 		"alignment",
				// 		"specialCharacters",
				// 		"horizontalLine",
				// 		"MathType",
				// 		"ChemType",
				// 	],
				// }}
				onChange={(event, editor) => {
					let remark1 = editor.getData();
					let e = {
						target: {
							value: remark1,
						},
					};
					// console.log({ editor });

					handleInputChangeForMutipleItem(e, index, "question");
				}}
				onBlur={(event, editor) => {
					console.log("Blur.", editor);
				}}
				onFocus={(event, editor) => {
					console.log("Focus.", editor);
				}}
			/>
			<div className="flex flex-col items-center gap-4 mt-8">
				{question?.file && (
					<img
						src={question?.file ? URL.createObjectURL(question?.file) : null}
						alt=""
						className="h-48 w-auto rounded-sm border"
					/>
				)}
				<div className="flex gap-6 ms-auto">
					{question?.file && (
						<button
							type="button"
							onClick={handleChangeImageRemoved(index, "file")}
							className="h-8 w-24 bg-main rounded-xl text-white barlow text-base font-normal">
							Remove
						</button>
					)}
					<div>
						<input
							ref={fileInputRef}
							type="file"
							accept="image/*"
							style={{ display: "none" }}
							onChange={handleChangeImage(index, "file")}
							name="image"
						/>
						<button
							type="button"
							className="h-8 w-36 bg-main rounded-xl text-white barlow text-base font-normal"
							onClick={() => fileInputRef.current.click()}>
							Upload Image
						</button>
					</div>
				</div>
			</div>
			<div className="mt-2">
				{/* Option A  */}
				<OptionCreate
					option={"option_a"}
					question={question}
					index={index}
					handleInputChangeForMutipleItem={handleInputChangeForMutipleItem}
					handleChangeImage={handleChangeImage}
					handleChangeImageRemoved={handleChangeImageRemoved}
					title="A"
				/>

				{/* Option B  */}
				<OptionCreate
					option={"option_b"}
					question={question}
					index={index}
					handleInputChangeForMutipleItem={handleInputChangeForMutipleItem}
					handleChangeImage={handleChangeImage}
					handleChangeImageRemoved={handleChangeImageRemoved}
					title="B"
				/>

				{/* Option C  */}
				<OptionCreate
					option={"option_c"}
					question={question}
					index={index}
					handleInputChangeForMutipleItem={handleInputChangeForMutipleItem}
					handleChangeImage={handleChangeImage}
					handleChangeImageRemoved={handleChangeImageRemoved}
					title="C"
				/>

				{/* Option D  */}
				<OptionCreate
					option={"option_d"}
					question={question}
					index={index}
					handleInputChangeForMutipleItem={handleInputChangeForMutipleItem}
					handleChangeImage={handleChangeImage}
					handleChangeImageRemoved={handleChangeImageRemoved}
					title="D"
				/>
				{/* Option E  */}
				<OptionCreate
					option={"option_e"}
					question={question}
					index={index}
					handleInputChangeForMutipleItem={handleInputChangeForMutipleItem}
					handleChangeImage={handleChangeImage}
					handleChangeImageRemoved={handleChangeImageRemoved}
					title="E"
				/>
			</div>
			{questionsLength > 1 && (
				<button
					onClick={() => handleDeleteRowForMutipleItem(index)}
					className="mb-4 px-4 py-2 bg-[#FD5709] rounded-full text-white w-12 h-12 flex justify-center items-center text-lg ms-auto">
					<HiTrash className="icon font-bold" size={24} />
				</button>
			)}
		</div>
	);
};

export default CreateQuestion;

export const OptionCreate = ({
	question,
	index,
	handleInputChangeForMutipleItem,
	handleChangeImage,
	handleChangeImageRemoved,
	option,
	title,
}) => {
	let fileInputRef = useRef();

	return (
		<>
			<div className="w-full p-2 mt-2 border border-gray-600 rounded flex items-center gap-x-4">
				<span className="text-lg font-semibold">{title}</span>
				<input
					type="text"
					className="w-full p-2 border-0 border-gray-300 rounded outline-none focus:outline-none focus:shadow-none shadow"
					value={question?.[option]}
					onChange={event =>
						handleInputChangeForMutipleItem(event, index, option)
					}
				/>
				<input
					type="radio"
					name={`answer${index}`}
					className="mr-3 shadow"
					value={question?.answer}
					checked={question?.answer === option}
					onChange={event =>
						handleInputChangeForMutipleItem(event, index, "answer", option)
					}
				/>
			</div>
			<div className="flex flex-col items-center gap-4 mt-8">
				{question?.[`${option}file`] && (
					<img
						src={
							question?.[`${option}file`]
								? URL.createObjectURL(question?.[`${option}file`])
								: null
						}
						alt=""
						className="h-48 w-auto rounded-sm border"
					/>
				)}
				<div className="flex gap-6 ms-auto">
					{question?.[`${option}file`] && (
						<button
							type="button"
							onClick={handleChangeImageRemoved(index, `${option}file`, "file")}
							className="h-8 w-24 bg-main rounded-xl text-white barlow text-base font-normal">
							Remove
						</button>
					)}
					<div>
						<input
							ref={fileInputRef}
							type="file"
							accept="image/*"
							style={{ display: "none" }}
							onChange={handleChangeImage(index, `${option}file`, "file")}
							name="image"
						/>
						<button
							type="button"
							className="h-8 w-36 bg-main rounded-xl text-white barlow text-base font-normal"
							onClick={() => fileInputRef.current.click()}>
							Upload Image
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

// import React ,{useState}from 'react';

// const Questions = ({quesiton}) => {
//   return (
//     <div className='mb-4 p-4 bg-white rounded-lg'>
//       <label htmlFor="">Question {quesiton.id}</label>
//       <input
//         type="text"
//         value={quesiton.answer}
//         onChange={(e) =>{}}
//        />

//        <div className='mt-2'>
//           <div>
//               <label htmlFor="a">A</label>
//               <div className='border-2 border-gray-300 rounded-lg flex justify-between'>
//                 <input type="text" id='' value={quesiton.answer} />
//                 <input type="radio"  />
//               </div>
//           </div>
//           {/* <div>
//               <label htmlFor="b">B</label>
//               <div className='border-2 border-gray-300 rounded-lg flex justify-between'>
//                 <input type="text" id='b' />
//                 <input type="radio"  />
//               </div>
//           </div>
//           <div>
//               <label htmlFor="c">C</label>
//               <div className='border-2 border-gray-300 rounded-lg flex justify-between'>
//                 <input type="text" id='c' />
//                 <input type="radio"  />
//               </div>
//           </div>
//           <div>
//               <label htmlFor="d">D</label>
//               <div className='border-2 border-gray-300 rounded-lg flex justify-between'>
//                 <input type="text" id='d' />
//                 <input type="radio"  />
//               </div>
//           </div> */}
//        </div>
//     </div>
//   )
// }

// export default Questions
