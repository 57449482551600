import React, {useState} from 'react'; 
// import Success from './success';
import {useSelector, useDispatch} from 'react-redux'
import {toast} from 'react-toastify'
import { Buttons } from "../button/button";
import ModalContainer from "../modal-container/modal-container";
import axios from 'axios'
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import {useLocation} from 'react-router-dom'
import { getSubject } from "../../data/Reducers/SubjectReducer";

const SelectModal = ({isOpenSelectModal, closeSelectModal, type="examType"}) => {
  const [selectOption, setSelectOption] = useState(""); 
  // const [isModalOpenSuccess, setIsModalOpenSuccess] = useState(false); 


  const handleSelectChange = (ev) => {
    setSelectOption(ev.target.value); 
  }


  // const openModalSuccess = () =>{
  //   setIsModalOpenSuccess(true)
  // }

  // const closeSuccess = () =>{
  //   setIsModalOpenSuccess(false)
  // }

  let [loading, setLoading]=useState(false),
  dispatch = useDispatch(),
  location = useLocation()

  const handleSubmit = async e => {
		e?.preventDefault();
		if (!selectOption) return toast.info(`${type === 'examType' ? "Subject":"Year"} is required`);
		setLoading(true);
		try {
      let data = {
        type
      }
      if (type === 'examType')
      data.subject = selectOption
    data.examType = location?.state?.examType || location?.state?._id
			let res = await axios.put(`/api/v1/exam`, data);
			console.log({ resp: res?.data });
			toast.success(res?.data?.message);
      if (type === 'examType')
      dispatch(getSubject(res?.data))
			closeSelectModal()
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
		}
		setLoading(false);
	};

  let {subject, year}=useSelector(s=>s)

  if (!isOpenSelectModal) return null; 
    
  return (
		<>
			{/* <div
				className="fixed top-0 left-0 z-50 w-full h-full bg-[rgba(0,0,0,0.2)] flex justify-center items-center p-4"
				onClick={closeSelectModal}>
				<div className="relative bg-white w-1/3 h-80  p-8">
					<h1 className="text-[24px] text-center font-bold">ADD SUBJECT</h1>
					<div className="my-12">
						<label htmlFor="subject-name" className="font-medium text-[20px]">
							SUBJECT NAME
						</label>
						<select
							className=" outline-none focus:outline-none w-full mt-4 rounded border-[#707070] font-medium uppercase"
							id="subject-name"
							value={selectOption}
							onChange={handleSelectChange}>
							<option value="" className="text-[20px] font-bold">
								LIST OF SUBJECT
							</option>
							{subject?.data?.map((it, i) => (
								<option value={it?._id}>{it?.title}</option>
							))}
						</select>
					</div>
					<div className="flex justify-center items-center my-6">
						<button
							className="bg-[#30388F] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-all duration-300 focus:outline-none focus:shadow-outline cursor-pointer"
							onClick={openModalSuccess}>
							Save
						</button>
						<div className="mt-8">
							<Success
								isOpenSuccess={isModalOpenSuccess}
								closeSuccess={closeSuccess}
							/>
						</div>
					</div>
				</div>
			</div> */}
			<ModalContainer
				title={type === "examType" ? "Add Subject" : "Add Year"}
				show={isOpenSelectModal}
				close={closeSelectModal}>
				<div className="px-6 py-4 overflow-y-auto ">
					{/* Add your content here */}
					<div className="popmodal__cover">
						<div className="popmodal__content ">
							<form>
								<div className="my-12">
									<label
										htmlFor="subject-name"
										className="font-medium text-[20px]">
										{type === "examType" ? "SUBJECT NAME" : "YEAR"}
									</label>
									<select
										className=" outline-none focus:outline-none w-full mt-4 rounded border-[#707070] font-medium uppercase shadow"
										id="subject-name"
										value={selectOption}
										onChange={handleSelectChange}>
										<option value="" className="text-[20px] font-bold">
											LIST OF {type === "examType" ? "SUBJECT" : "Years"}
										</option>
										{type === "examType" &&
											subject?.data?.docs
												?.filter(it => {
													let tt =
														location?.state?.examType || location?.state?._id;
													return !it?.examType?.includes(tt);
												})
												?.map((it, i) => (
													<option value={it?._id}>{it?.title}</option>
												))}
										{type !== "examType" &&
											year?.data?.docs?.map((it, i) => (
												<option value={it?._id}>{it?.title}</option>
											))}
									</select>
								</div>
								<div className="flex justify-center items-center my-6">
									<Buttons
										// buttonType={"primary"}
										title={"Save"}
										type="submit"
										width={
											"w-fit bg-[#30388F] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-all duration-300 focus:outline-none focus:shadow-outline cursor-pointer"
										}
										loading={loading}
										onClick={handleSubmit}
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
			</ModalContainer>
		</>
	);}

export default SelectModal
