import { useNavigate } from "react-router-dom";
import { MainUsers } from "../users";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const Users = () => {
	let navigate = useNavigate(),
		{ auth } = useSelector(s => s);

	useEffect(() => {
		if (auth?.user?.privilege !== "superadmin") navigate(-1);
	}, [auth?.user?.privilege, navigate]);

	return <MainUsers privilege="admin" />;
};

export default Users;
