/* eslint-disable react/prop-types */
import { createContext } from "react";
import { BiCategoryAlt, BiEdit, BiBook } from "react-icons/bi";
import { MdGrade } from "react-icons/md";
import { PiNotepadBold } from "react-icons/pi";
import { BsPerson, BsPersonBadge } from "react-icons/bs";
import { LiaMedalSolid } from "react-icons/lia";

// import { RxPencil2 } from "react-icons/rx";
import { useSelector } from "react-redux";
import { useState } from "react";

export const GlobalState = createContext();

const DataProvider = ({ children }) => {
	const [nav, setNav] = useState(false);
	let handleCapitalize = word => {
		let splitter = word.trim().split(" ");
		let firstCap = splitter[0].split("");
		let remaining = splitter.slice(1, splitter.length).join(" ");

		let firstCapOne = firstCap[0].toUpperCase();
		let firstCapTwo = firstCap.slice(1, firstCap.length).join("");

		let joinFirst = `${firstCapOne}${firstCapTwo}`;

		return `${joinFirst} ${remaining}`;
	};

	let numberWithCommas = x => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	const toggleNav = () => {
		setNav(!nav);
	};

	let { auth } = useSelector(state => state);

	let sidebarList = [
		{
			name: "Dashboard",
			url: "/dashboard",
			icon: <BiCategoryAlt className="icon" size={28} />,
			permission: ["admin", "user", "organisation", "superadmin"],
			show: true,
		},
		{
			name: "Manage Exams",
			url: "/manage-exams",
			icon: <BiEdit className="icon" size={28} />,
			permission: [],
			show: true,
		},
		{
			name: "Manage Subjects",
			url: "/manage-subjects",
			icon: <BiBook className="icon" size={28} />,
			type: "button",
			permission: ["user", "organisation"],
			show: true,
		},
		{
			name: "View Results",
			url: "/view-results",
			icon: <PiNotepadBold className="icon" size={28} />,
			permission: ["admin", "user", "organisation", "superadmin"],
			show: true,
		},
		{
			name: "Manage Grade",
			url: "/manage-grade",
			icon: <MdGrade className="icon" size={28} />,
			permission: ["admin", "user", "organisation", "superadmin"],
			show: true,
		},
		{
			name: "LeaderBoard",
			url: "/leaderboard",
			icon: <LiaMedalSolid className="icon" size={24} />,
			type: "button",
			permission: ["user", "organisation"],
			show: true,
		},
		{
			name: "Users",
			url: "/users",
			icon: <BsPerson className="icon" size={24} />,
			type: "button",
			permission: ["user", "organisation"],
			show: true,
		},
		{
			name: "Admins",
			url: "/admin",
			icon: <BsPersonBadge className="icon" size={24} />,
			type: "button",
			permission: ["user", "organisation"],
			show: auth?.user?.privilege === "superadmin",
		},
	];

	const state = {
		handleCapitalize,
		numberWithCommas,
		sidebarList,
		auth,
		nav,
		toggleNav,
	};

	return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};

export default DataProvider;
